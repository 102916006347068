







import Vue from 'vue'

import SelectThing from '@/components/Select/Thing.vue'
import Things from '@/components/Card/Things/Index.vue'

export default Vue.extend({
  components: {
    SelectThing,
    Things
  }
})
