











import Vue from 'vue'
import { ThingStatusTypeEnum } from '@/utils/thingStatus'

export default Vue.extend({
  data() {
    return {
      ThingStatusTypeEnum
    }
  },
  props: {
    compositeSerial: {
      type: String,
      required: true
    }
  },
  computed: {
    compositeConnected() {
      return this.$store.getters['composite/compositeConnected'](this.compositeSerial)
    },
    compositeOpStatusType() {
      return this.$store.getters['composite/compositeOpStatus'](this.compositeSerial)
    }
  }
})
