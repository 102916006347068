









import Vue from 'vue'

import Client from '@/components/Card/Things/Client.vue'
import Diagnostic from '@/components/Card/Things/Diagnostic.vue'
import Header from '@/components/Card/Things/Header.vue'
import Status from '@/components/Card/Things/Status.vue'

export default Vue.extend({
  components: {
    Header,
    Status,
    Diagnostic,
    Client
  },
  props: {
    compositeSerial: {
      type: String,
      required: true
    }
  }
})
