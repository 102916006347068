var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.compositeConnected
      ? _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("Card.Status.DisconnectedThing")) + " ")
        ])
      : _vm.compositeConnected &&
        _vm.compositeOpStatusType === _vm.ThingStatusTypeEnum.Error
      ? _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("Card.Status.OpStatusError")) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }