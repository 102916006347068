var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c("div", [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "skeleton-container" },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("Default.Loading")))]),
                _c("Skeleton", { attrs: { type: "card-loading" } }),
                _c("Skeleton", { attrs: { type: "card-loading" } }),
                _c("Skeleton", { attrs: { type: "card-loading" } })
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "container" },
              [
                _c("SensitivityMaster"),
                _vm._l(_vm.compositeThings, function(compositeThing, index) {
                  return _c("Thing", {
                    key: index,
                    staticClass: "card",
                    attrs: { compositeSerial: compositeThing.serial }
                  })
                })
              ],
              2
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }