





















import Vue from 'vue'
import { mapState } from 'vuex'

import SensitivityMaster from '@/components/Card/Settings/SensitivityMaster.vue'
import Thing from '@/components/Card/Things/Thing.vue'
import { IStateThing } from '@/store/modules/thing'
import { IStateComposite } from '@/store/modules/composite'
import { REFRESH_THING_STATE_MS } from '@/utils/constant'

export default Vue.extend({
  data() {
    return {
      loading: false,
      timeout: 0
    }
  },
  components: {
    SensitivityMaster,
    Thing
  },
  methods: {
    getComposites() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.$store.dispatch('composite/getCompositeStatus').finally(() => {
        this.timeout = setTimeout(this.getComposites, REFRESH_THING_STATE_MS)
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateComposite>('composite', {
      compositeThings: (state: IStateComposite) => state.compositeThings
    })
  },
  watch: {
    serial: function() {
      clearTimeout(this.timeout)
      this.$store.commit('thing/resetThing')
      this.$store.commit('composite/resetComposite')
      this.$store.commit('diagnostic/resetCompositeDiagnostic')
      this.$store.commit('client/resetCompositeConnectedClients')
      this.getComposites()
    }
  },
  mounted() {
    clearTimeout(this.timeout)
    this.loading = true
    this.getComposites()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
})
