var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      attrs: {
        to: { name: "settings", params: { serial: _vm.compositeSerial } }
      }
    },
    [
      _c("Header", { attrs: { compositeSerial: _vm.compositeSerial } }),
      _c("Status", { attrs: { compositeSerial: _vm.compositeSerial } }),
      _c("Client", { attrs: { compositeSerial: _vm.compositeSerial } }),
      _c("Diagnostic", { attrs: { compositeSerial: _vm.compositeSerial } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }