var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compositeStatus.connected &&
    _vm.compositeStatus.agentWifiOpMode === _vm.AgentModeEnum.Ap
    ? _c("div", [
        !_vm.compositeOnlineClients
          ? _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("Card.Client.DisconnectedClient")) + " "
              )
            ])
          : !_vm.compositeClientsMatch
          ? _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("Card.Client.Updating")) + " ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }