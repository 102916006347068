











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AgentModeEnum } from '@/store/modules/composite'

@Component
export default class Client extends Vue {
  private AgentModeEnum: typeof AgentModeEnum = AgentModeEnum
  @Prop({ required: true }) readonly compositeSerial!: string

  get compositeStatus() {
    return this.$store.getters['composite/compositeStatus'](this.compositeSerial)
  }

  get compositeConnected() {
    return this.$store.getters['composite/compositeConnected'](this.compositeSerial)
  }

  get compositeOnlineClients() {
    return this.$store.getters['client/compositeOnlineClients'](this.compositeSerial)
  }

  get compositeClientsMatch() {
    return this.$store.getters['composite/compositeClientsMatch'](this.compositeSerial)
  }

  @Watch('compositeConnected', { immediate: true })
  refreshClients(): void {
    const serial = this.compositeSerial
    if (!this.compositeConnected) return

    this.$store.dispatch('client/getCompositeConnectedClients', { serial })
  }
}
