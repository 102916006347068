

































import Vue from 'vue'

export default Vue.extend({
  computed: {
    barWidth() {
      const component = this as any
      const value = component.compositeSensitivityMaster
      const currentStepWidth = value !== null ? value * 25 : 0

      return `width: ${currentStepWidth}%;`
    },
    compositeSensitivityMaster() {
      return this.$store.getters['composite/compositeSensitivityMaster']
    },
    compositesConnected() {
      return this.$store.getters['composite/compositesConnected']
    },
    compositeSensitivitiesMatch() {
      return this.$store.getters['composite/compositeSensitivitiesMatch']
    }
  },
  methods: {
    tickStyles(value: any) {
      if (this.compositeSensitivityMaster === null) {
        return 'backgroundColor: #535756'
      } else if (this.compositeSensitivityMaster !== null && value < this.compositeSensitivityMaster!) {
        return 'backgroundColor: #dd2c2c'
      }
    },
    setValue(value: any) {
      this.$store.dispatch('composite/setCompositeSensitivityMaster', { value })
    }
  }
})
